@charset "UTF-8";
.pipeline-metadata {
  background: var(--color-sidebar-background);
  border-bottom: 1px solid var(--color-sidebar-border);
  box-shadow: 0 1px 3px var(--color-sidebar-shadow); }

.pipeline-metadata__expand-plot {
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer; }
  .pipeline-metadata__expand-plot:focus {
    outline: none; }
    [data-whatintent='keyboard'] .pipeline-metadata__expand-plot:focus {
      box-shadow: 0 0 0 3px rgba(0, 176, 245, 0.85) inset; }

.kui-theme--light {
  --color-metadata-bg: #f0f1f3;
  --color-metadata-kind-token-bg: #e6e8eb;
  --color-plot-bg: #eeeeee;
  --color-button-plot: #003464;
  --color-button-plot-hovered: #005d92;
  --color-button-text: white; }

.kui-theme--dark {
  --color-metadata-bg: #122531;
  --color-metadata-kind-token-bg: #2a3b46;
  --color-plot-bg: #111111;
  --color-button-plot: #003464;
  --color-button-plot-hovered: #005d92;
  --color-button-text: white; }

.pipeline-metadata {
  position: absolute;
  top: -1px;
  /* Avoids pixel rounding gaps */
  right: 0;
  bottom: -1px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 6px 0 0 0;
  overflow-y: auto;
  background: var(--color-metadata-bg);
  transform: translateX(100%);
  transition: transform ease 0.4s; }
  .pipeline-metadata--visible {
    transform: translateX(0); }
  @media print {
    .pipeline-metadata {
      display: none; } }

.pipeline-metadata__close-button {
  position: absolute;
  top: 2px;
  right: -14px; }
  .pipeline-metadata__close-button,
  .pipeline-metadata__close-button svg {
    width: 24px;
    height: 24px; }

.pipeline-metadata__label,
.pipeline-metadata__row,
.pipeline-metadata__header-toolbox {
  margin: 1.9em 36px;
  font-size: 1.48em; }

.pipeline-metadata__header {
  display: flex;
  justify-content: space-between;
  margin-top: -0.12em; }

.pipeline-metadata__header-toolbox {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-top: 1.7em;
  padding-right: 24px; }

.pipeline-metadata__icon {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  margin: 0.05em 0.55em 0 -0.3em;
  fill: var(--color-text); }

.pipeline-metadata__title {
  flex-grow: 1;
  margin: 0;
  font-weight: normal;
  font-size: 1.3em;
  line-height: 1.6;
  word-break: break-word; }

.pipeline-metadata__list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0; }

.pipeline-metadata__label {
  margin-bottom: 0.7em;
  font-weight: normal;
  opacity: 0.65;
  user-select: none; }

.pipeline-metadata__row {
  margin-top: 0.7em; }

.pipeline-metadata__value {
  display: inline;
  flex-grow: 0;
  margin-right: auto;
  line-height: 1.5;
  word-break: break-word;
  white-space: pre-wrap; }

.pipeline-metadata__value--kind-type,
.pipeline-metadata__value--kind-path {
  display: block;
  overflow: hidden;
  direction: rtl;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis; }

.pipeline-metadata__value--kind-token {
  display: inline-block;
  padding: 0.18em 0.6em 0.2em 0.6em;
  font-weight: 600;
  background: var(--color-metadata-kind-token-bg); }
  code .pipeline-metadata__value--kind-token {
    padding: 0.4em 0.8em;
    font-weight: normal;
    font-size: 0.9em;
    font-family: Consolas, Monaco, 'Courier New', Courier, monospace; }

.pipeline-metadata__value-list-expand {
  display: block;
  margin: 0.6em 0 0 0;
  padding: 0 0 0.12em 0;
  color: var(--color-text);
  font-size: 0.96em;
  background: none;
  border: 0;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  opacity: 0.45;
  appearance: none; }
  .pipeline-metadata__value-list-expand:hover {
    border-bottom-color: var(--color-text);
    opacity: 0.55; }

.pipeline-metadata__value-list--no-inline {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.pipeline-metadata__value-list--no-inline li {
  position: relative;
  margin-left: 1em; }
  .pipeline-metadata__value-list--no-inline li:before {
    position: absolute;
    margin-left: -1em;
    content: '▪'; }

.pipeline-metadata__value-list--inline {
  display: flex;
  flex-wrap: wrap;
  margin: -0.3em;
  padding: 0;
  list-style: none; }
  .pipeline-metadata__value-list--inline li {
    display: inline;
    margin: 0.2em 0.3em; }

.pipeline-metadata__value-list--commas li:not(:last-child):after {
  content: ', '; }

.pipeline-metadata__toolbox-container {
  position: relative;
  display: flex; }

.pipeline-metadata__toolbox {
  display: block;
  width: 26px;
  height: 34px;
  margin: -0.5em 0 0 auto;
  padding: 0;
  list-style: none; }

.pipeline-metadata__run-command-value {
  display: flex;
  flex-grow: 1;
  align-items: center; }
  .pipeline-metadata__run-command-value--no-visible {
    opacity: 0; }

.pipeline-metadata__copy-button {
  width: auto;
  height: auto;
  margin-top: 0.3em;
  margin-right: -0.6em;
  opacity: 0.55; }
  .pipeline-metadata__copy-button .pipeline-icon {
    width: 26px;
    height: 26px; }
  .pipeline-metadata__copy-button:hover {
    opacity: 1; }
  .pipeline-metadata__copy-button:active {
    transform: translateY(1px);
    opacity: 0.85; }

.pipeline-metadata__copy-message {
  position: absolute;
  top: 0;
  left: 0; }
  .pipeline-metadata__copy-message--no-visible {
    display: none; }

.pipeline-metadata__plot {
  display: flex;
  height: 300px;
  cursor: pointer; }

.pipeline-metadata__properties {
  flex-grow: 1;
  height: 0px;
  margin-top: -1.9em;
  overflow-y: auto; }

.pipeline-metadata__expand-plot {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  background-color: var(--color-button-plot); }
  .pipeline-metadata__expand-plot:hover {
    background-color: var(--color-button-plot-hovered); }

.pipeline-metadata__expand-plot-icon {
  width: 1.9em;
  height: 1.9em;
  margin: 0 6px 0 36px;
  fill: var(--color-button-text); }

.pipeline-metadata__expand-plot-text {
  margin: 12px 10px 12px 5px;
  color: var(--color-button-text);
  font-size: 1.6em; }

.pipeline-metadata__object .react-json-view {
  font-size: 0.85em; }
