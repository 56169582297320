.pipeline-plot-modal__back, .pipeline-plot-modal__collapse-plot {
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer; }
  .pipeline-plot-modal__back:focus, .pipeline-plot-modal__collapse-plot:focus {
    outline: none; }
    [data-whatintent='keyboard'] .pipeline-plot-modal__back:focus, [data-whatintent='keyboard'] .pipeline-plot-modal__collapse-plot:focus {
      box-shadow: 0 0 0 3px rgba(0, 176, 245, 0.85) inset; }

.kui-theme--light {
  --color-bg-plot: #eeeeee;
  --color-button-plot: #003464;
  --color-button-plot-hovered: #005d92;
  --color-button-text: white; }

.kui-theme--dark {
  --color-bg-plot: #111111;
  --color-button-plot: #003464;
  --color-button-plot-hovered: #005d92;
  --color-button-text: white; }

.pipeline-plotly-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  background-color: var(--color-bg-plot); }
  .pipeline-plotly-modal .pipeline-plotly-chart {
    width: calc(98% - 80px);
    height: calc(92% - 80px);
    margin: auto; }

.pipeline-plot-modal__top {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  min-width: 100%;
  padding: 1.5em; }

.pipeline-plot-modal__header {
  display: flex;
  margin: 0 auto;
  padding: 0 32px; }

.pipeline-plot-modal__icon {
  display: inline-block;
  width: 2.9em;
  height: 2.8em;
  margin: 0 10px 0 0;
  fill: var(--color-text); }

.pipeline-plot-modal__title {
  margin-top: 1px;
  font-size: 1.8em; }

.pipeline-plot-modal__back {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px; }

.pipeline-plot-modal__back-text {
  font-size: 1.6em; }

.pipeline-plot-modal__back-icon {
  width: 1.9em;
  height: 1.9em;
  margin: 0 12px 0 0;
  fill: var(--color-text); }

.pipeline-plot-modal__bottom {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  min-width: 100%; }

.pipeline-plot-modal__collapse-plot {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  width: 400px;
  background-color: var(--color-button-plot); }
  .pipeline-plot-modal__collapse-plot:hover {
    background-color: var(--color-button-plot-hovered); }

.pipeline-plot-modal__collapse-plot-icon {
  width: 1.9em;
  height: 1.9em;
  margin: 0 6px -0.1em 36px;
  fill: var(--color-button-text); }

.pipeline-plot-modal__collapse-plot-text {
  margin: 12px 10px 12px 5px;
  color: var(--color-button-text);
  font-size: 1.6em; }

@media (max-width: 700px) {
  .pipeline-plot-modal__collapse-plot {
    width: auto; }
  .pipeline-plot-modal__collapse-plot-text {
    display: none; }
  .pipeline-plot-modal__collapse-plot-icon {
    margin: 10px; } }
