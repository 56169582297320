.kui-theme--light {
  --pipeline-list-shadow: rgba(black, 0.03);
  --pipeline-list-label-bg: #f8f9fa;
  --pipeline-list-options-bg: #f0f1f3;
  --pipeline-list-option-border-width: 1px 0 0 2px;
  --pipeline-list-option-border-color: rgba(0, 0, 0, 0.06);
  --pipeline-list-option-bg-hover: rgba(255, 255, 255, 0.3); }

.kui-theme--dark {
  --pipeline-list-shadow: rgba(black, 0.1);
  --pipeline-list-label-bg: #122531;
  --pipeline-list-options-bg: #001521;
  --pipeline-list-option-border-width: 0 0 1px 2px;
  --pipeline-list-option-border-color: rgba(255, 255, 255, 0.06);
  --pipeline-list-option-bg-hover: rgba(255, 255, 255, 0.03); }

.pipeline-list .kui-dropdown {
  display: block;
  border: none;
  box-shadow: 0 1px 1px var(--pipeline-list-shadow) !important; }

.pipeline-list .kui-dropdown__label,
.pipeline-list .kui-menu-option {
  height: 58px; }

.pipeline-list .kui-dropdown__label {
  padding: 0 1.125em;
  background: var(--pipeline-list-label-bg); }
  .pipeline-list .kui-dropdown__label:focus {
    outline: none;
    box-shadow: 0 0 0 4px #00b0f5 inset; }
    [data-whatinput='mouse'] .pipeline-list .kui-dropdown__label:focus {
      box-shadow: none; }

.pipeline-list .kui-dropdown__options {
  z-index: 2;
  background: var(--pipeline-list-options-bg);
  border-top: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1); }
  .pipeline-list .kui-dropdown__options > section {
    padding: 0; }

.pipeline-list .kui-menu-option {
  padding: 0 1.8em 0 calc(1.8em - 2px);
  border-color: var(--pipeline-list-option-border-color) transparent;
  border-style: solid;
  border-width: var(--pipeline-list-option-border-width); }
  .pipeline-list .kui-menu-option--focused, .pipeline-list .kui-menu-option:focus, .pipeline-list .kui-menu-option:hover {
    background: var(--pipeline-list-option-bg-hover); }
  .pipeline-list .kui-menu-option.pipeline-list__option--active {
    border-left-color: #00b0f5; }
