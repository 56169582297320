.pipeline-tooltip__text {
  color: var(--color-text-alt);
  background: var(--color-bg-alt);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }

.pipeline-tooltip {
  position: absolute;
  top: -10px;
  left: -20px;
  z-index: 9;
  width: 100vw;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease 0.1s, visibility ease 0.1s;
  pointer-events: none; }
  .pipeline-tooltip--visible {
    visibility: visible;
    opacity: 1; }
  .pipeline-tooltip--right {
    right: -20px;
    left: auto; }
  .pipeline-tooltip--top {
    top: 10px; }
  .pipeline-tooltip::after {
    position: absolute;
    bottom: -9.5px;
    left: 10px;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-top-color: var(--color-bg-alt);
    content: ''; }
  .pipeline-tooltip--right::after {
    right: 10px;
    left: auto; }
  .pipeline-tooltip--top::after {
    top: -9.5px;
    bottom: auto;
    border-width: 0 10px 10px 10px;
    border-top-color: transparent;
    border-bottom-color: var(--color-bg-alt); }

.pipeline-tooltip__text {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: calc(50vw - 150px);
  padding: 12px 20px;
  font-size: 1.5em;
  overflow-wrap: break-word; }
  .pipeline-tooltip--right .pipeline-tooltip__text {
    right: 0;
    left: auto; }
  .pipeline-tooltip--top .pipeline-tooltip__text {
    top: 0;
    bottom: auto; }
