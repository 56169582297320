.pipeline-metadata-code {
  background: var(--color-sidebar-background);
  border-bottom: 1px solid var(--color-sidebar-border);
  box-shadow: 0 1px 3px var(--color-sidebar-shadow); }

.kui-theme--light .pipeline-metadata-code__code {
  /* a11y-light theme */
  /* Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css */
  /* @author: ericwbailey */
  /* Comment */
  /* Red */
  /* Orange */
  /* Yellow */
  /* Green */
  /* Blue */
  /* Purple */ }
  .kui-theme--light .pipeline-metadata-code__code .hljs-comment,
  .kui-theme--light .pipeline-metadata-code__code .hljs-quote {
    color: #696969; }
  .kui-theme--light .pipeline-metadata-code__code .hljs-variable,
  .kui-theme--light .pipeline-metadata-code__code .hljs-template-variable,
  .kui-theme--light .pipeline-metadata-code__code .hljs-tag,
  .kui-theme--light .pipeline-metadata-code__code .hljs-name,
  .kui-theme--light .pipeline-metadata-code__code .hljs-selector-id,
  .kui-theme--light .pipeline-metadata-code__code .hljs-selector-class,
  .kui-theme--light .pipeline-metadata-code__code .hljs-regexp,
  .kui-theme--light .pipeline-metadata-code__code .hljs-deletion {
    color: #d91e18; }
  .kui-theme--light .pipeline-metadata-code__code .hljs-number,
  .kui-theme--light .pipeline-metadata-code__code .hljs-built_in,
  .kui-theme--light .pipeline-metadata-code__code .hljs-builtin-name,
  .kui-theme--light .pipeline-metadata-code__code .hljs-literal,
  .kui-theme--light .pipeline-metadata-code__code .hljs-type,
  .kui-theme--light .pipeline-metadata-code__code .hljs-params,
  .kui-theme--light .pipeline-metadata-code__code .hljs-meta,
  .kui-theme--light .pipeline-metadata-code__code .hljs-link {
    color: #aa5d00; }
  .kui-theme--light .pipeline-metadata-code__code .hljs-attribute {
    color: #aa5d00; }
  .kui-theme--light .pipeline-metadata-code__code .hljs-string,
  .kui-theme--light .pipeline-metadata-code__code .hljs-symbol,
  .kui-theme--light .pipeline-metadata-code__code .hljs-bullet,
  .kui-theme--light .pipeline-metadata-code__code .hljs-addition {
    color: #008000; }
  .kui-theme--light .pipeline-metadata-code__code .hljs-title,
  .kui-theme--light .pipeline-metadata-code__code .hljs-section {
    color: #007faa; }
  .kui-theme--light .pipeline-metadata-code__code .hljs-keyword,
  .kui-theme--light .pipeline-metadata-code__code .hljs-selector-tag {
    color: #7928a1; }
  .kui-theme--light .pipeline-metadata-code__code .hljs {
    display: block;
    overflow-x: auto;
    background: #fefefe;
    color: #545454;
    padding: 0.5em; }
  .kui-theme--light .pipeline-metadata-code__code .hljs-emphasis {
    font-style: italic; }
  .kui-theme--light .pipeline-metadata-code__code .hljs-strong {
    font-weight: bold; }
  @media screen and (-ms-high-contrast: active) {
    .kui-theme--light .pipeline-metadata-code__code .hljs-addition,
    .kui-theme--light .pipeline-metadata-code__code .hljs-attribute,
    .kui-theme--light .pipeline-metadata-code__code .hljs-built_in,
    .kui-theme--light .pipeline-metadata-code__code .hljs-builtin-name,
    .kui-theme--light .pipeline-metadata-code__code .hljs-bullet,
    .kui-theme--light .pipeline-metadata-code__code .hljs-comment,
    .kui-theme--light .pipeline-metadata-code__code .hljs-link,
    .kui-theme--light .pipeline-metadata-code__code .hljs-literal,
    .kui-theme--light .pipeline-metadata-code__code .hljs-meta,
    .kui-theme--light .pipeline-metadata-code__code .hljs-number,
    .kui-theme--light .pipeline-metadata-code__code .hljs-params,
    .kui-theme--light .pipeline-metadata-code__code .hljs-string,
    .kui-theme--light .pipeline-metadata-code__code .hljs-symbol,
    .kui-theme--light .pipeline-metadata-code__code .hljs-type,
    .kui-theme--light .pipeline-metadata-code__code .hljs-quote {
      color: highlight; }
    .kui-theme--light .pipeline-metadata-code__code .hljs-keyword,
    .kui-theme--light .pipeline-metadata-code__code .hljs-selector-tag {
      font-weight: bold; } }

.kui-theme--dark .pipeline-metadata-code__code {
  /* a11y-dark theme */
  /* Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css */
  /* @author: ericwbailey */
  /* Comment */
  /* Red */
  /* Orange */
  /* Yellow */
  /* Green */
  /* Blue */
  /* Purple */ }
  .kui-theme--dark .pipeline-metadata-code__code .hljs-comment,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-quote {
    color: #d4d0ab; }
  .kui-theme--dark .pipeline-metadata-code__code .hljs-variable,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-template-variable,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-tag,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-name,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-selector-id,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-selector-class,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-regexp,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-deletion {
    color: #ffa07a; }
  .kui-theme--dark .pipeline-metadata-code__code .hljs-number,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-built_in,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-builtin-name,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-literal,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-type,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-params,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-meta,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-link {
    color: #f5ab35; }
  .kui-theme--dark .pipeline-metadata-code__code .hljs-attribute {
    color: #ffd700; }
  .kui-theme--dark .pipeline-metadata-code__code .hljs-string,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-symbol,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-bullet,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-addition {
    color: #abe338; }
  .kui-theme--dark .pipeline-metadata-code__code .hljs-title,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-section {
    color: #00e0e0; }
  .kui-theme--dark .pipeline-metadata-code__code .hljs-keyword,
  .kui-theme--dark .pipeline-metadata-code__code .hljs-selector-tag {
    color: #dcc6e0; }
  .kui-theme--dark .pipeline-metadata-code__code .hljs {
    display: block;
    overflow-x: auto;
    background: #2b2b2b;
    color: #f8f8f2;
    padding: 0.5em; }
  .kui-theme--dark .pipeline-metadata-code__code .hljs-emphasis {
    font-style: italic; }
  .kui-theme--dark .pipeline-metadata-code__code .hljs-strong {
    font-weight: bold; }
  @media screen and (-ms-high-contrast: active) {
    .kui-theme--dark .pipeline-metadata-code__code .hljs-addition,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-attribute,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-built_in,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-builtin-name,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-bullet,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-comment,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-link,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-literal,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-meta,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-number,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-params,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-string,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-symbol,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-type,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-quote {
      color: highlight; }
    .kui-theme--dark .pipeline-metadata-code__code .hljs-keyword,
    .kui-theme--dark .pipeline-metadata-code__code .hljs-selector-tag {
      font-weight: bold; } }

.kui-theme--light {
  --color-metadata-code-bg: #f8f9fa; }

.kui-theme--dark {
  --color-metadata-code-bg: #061c28; }

.pipeline-metadata-code {
  position: absolute;
  top: -1px;
  /* Avoids pixel rounding gaps */
  right: 0;
  bottom: -1px;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0 400px 0 0;
  background: var(--color-metadata-code-bg);
  transform: translateX(100vw);
  transition: transform ease 0.5s 0.1s, left ease 0.5s; }
  .pipeline-metadata-code--visible {
    transform: translateX(0); }
  @media (min-width: 1200px) {
    .pipeline-metadata-code--sidebarVisible {
      left: 424px; } }
  @media (min-width: 1304px) {
    .pipeline-metadata-code--sidebarVisible {
      left: calc( 100% - 904px); } }
  @media (min-width: 1200px) {
    .pipeline-metadata-code--no-sidebarVisible {
      left: 80px; } }
  @media (min-width: 960px) {
    .pipeline-metadata-code--no-sidebarVisible {
      left: calc( 100% - 904px); } }
  @media print {
    .pipeline-metadata-code {
      display: none; } }

.pipeline-metadata-code__title {
  flex-grow: 0;
  margin: 2.1em 36px 1.8em 36px;
  font-weight: normal;
  font-size: 1.7em;
  line-height: 1.6; }

.pipeline-metadata-code__code {
  display: block;
  flex-grow: 1;
  overflow: auto;
  font-size: 1.25em;
  line-height: 1.8;
  opacity: 1;
  transition: opacity 0.4s ease 0.4s; }
  .pipeline-metadata-code--no-visible .pipeline-metadata-code__code {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .pipeline-metadata-code__code * {
    font-family: Consolas, Monaco, 'Courier New', Courier, monospace; }
  .pipeline-metadata-code__code .hljs-keyword {
    color: var(--color-metadata-code-text); }

.pipeline-metadata-code pre {
  display: inline-block;
  margin: 0;
  padding: 0 36px 36px; }
