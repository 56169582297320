.pipeline-warning {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc((100% - 56px));
  height: 100%;
  text-align: center;
  transform: translateX(56px);
  transition: transform 0.4s ease, width ease 0.4s; }
  @media (min-width: 700px) {
    .pipeline-warning--sidebar-visible {
      width: calc((100% - 400px));
      transform: translateX(400px); } }

.pipeline-warning__title, .pipeline-warning__subtitle {
  width: 90%;
  margin: 0 auto; }

.pipeline-warning__title {
  margin-bottom: 0.6em;
  font-weight: normal;
  font-size: 2.8em; }
  @media (min-width: 700px) {
    .pipeline-warning__title {
      font-size: 3.8em; } }

.pipeline-warning__subtitle {
  max-width: 30em;
  margin-bottom: 2.4em;
  font-size: 1.4em; }
  @media (min-width: 700px) {
    .pipeline-warning__subtitle {
      font-size: 1.5em; } }

.pipeline-warning .kui-button:first-of-type {
  margin-bottom: 1.2em; }
