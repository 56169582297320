/**
 * List of colour variables for the different parts of the minimap
 */
.kui-theme--light {
  --minimap-bg: #a6aeb6;
  --minimap-shadow: rgba(255, 255, 255, 0.5);
  --minimap-viewport-stroke: #d3dee3;
  --minimap-node-fill-default: #f0f1f3;
  --minimap-node-fill-active: white;
  --minimap-node-fill-selected: #81f4ff;
  --minimap-node-fill-faded: #c3c8ce; }

.kui-theme--dark {
  --minimap-bg: #051116;
  --minimap-shadow: rgba(255, 255, 255, 0.05);
  --minimap-viewport-stroke: #8e8e90;
  --minimap-node-fill-default: #2a3b46;
  --minimap-node-fill-active: #293b46;
  --minimap-node-fill-selected: #00b0f5;
  --minimap-node-fill-faded: #0e1b24; }

.pipeline-minimap-node rect {
  fill: var(--minimap-node-fill-default); }

.pipeline-minimap-node--active rect {
  fill: var(--minimap-node-fill-active); }

.pipeline-minimap-node--selected rect {
  fill: var(--minimap-node-fill-selected); }

.pipeline-minimap-node--faded rect {
  fill: var(--minimap-node-fill-faded); }

.pipeline-minimap__graph {
  display: block; }

.pipeline-minimap-container {
  /* Sub-pixel adjustment */
  position: absolute;
  top: calc(100% + 2px);
  left: 100%;
  width: 500px;
  height: 222px;
  background: var(--minimap-bg);
  box-shadow: 0 0 2px var(--minimap-shadow);
  transform: translate(-100%, -100%);
  transition: transform 300ms ease; }

.pipeline-minimap {
  position: absolute;
  top: 0;
  right: 0; }

.pipeline-minimap__viewport {
  cursor: move;
  fill: rgba(0, 0, 0, 0);
  /* Solid for pointer events */
  stroke-width: 1px;
  stroke: var(--minimap-viewport-stroke); }
