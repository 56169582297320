/**
 * Themed colour variables for the flowchart component
 */
.kui-theme--light {
  --node-icon-fill: rgba(0, 0, 0, 0.85);
  --node-fill-default: #f0f1f3;
  --node-stroke-default: #c6cace;
  --node-fill-active: #c6e6fb;
  --node-stroke-active: #b2cada;
  --node-fill-selected: #aad8ff;
  --node-stroke-selected: #00b0f5;
  --node-labeltext-fill: rgba(0, 0, 0, 0.85);
  --edge-stroke: #888;
  --edge-arrowhead-fill: #2a3b46;
  --layer-fill: #d9dde1;
  --layer-text: rgba(0, 0, 0, 0.65);
  --node-input-fill: rgba(0, 0, 0, 0.4);
  --parameter-accent: #a000bc;
  --parameter-accent-input: rgba(160, 0, 188, 0.6); }
  .kui-theme--light.pipeline-graph--export {
    --node-fill-default: #f0f1f3;
    --node-stroke-default: #6b6a6b;
    --edge-stroke: #686768;
    --edge-arrowhead-fill: #686768; }
  @media print {
    .kui-theme--light {
      --node-fill-default: #f0f1f3;
      --node-stroke-default: #6b6a6b;
      --edge-stroke: #686768;
      --edge-arrowhead-fill: #686768; } }

.kui-theme--dark {
  --node-icon-fill: rgba(255, 255, 255, 0.85);
  --node-fill-default: #2a3b46;
  --node-stroke-default: #354854;
  --node-fill-active: #124066;
  --node-stroke-active: #234f73;
  --node-fill-selected: #004d8c;
  --node-stroke-selected: #00b0f5;
  --node-labeltext-fill: rgba(255, 255, 255, 0.85);
  --edge-stroke: #666;
  --edge-arrowhead-fill: #cacaca;
  --layer-fill: #021824;
  --layer-text: rgba(255, 255, 255, 0.65);
  --node-input-fill: rgba(255, 255, 255, 0.4);
  --parameter-accent: #ffbc00;
  --parameter-accent-input: rgba(255, 188, 0, 0.6); }

.pipeline-node text {
  font-weight: 600;
  font-size: 1.4em;
  pointer-events: none;
  fill: var(--node-labeltext-fill); }

.pipeline-node .pipeline-node__bg {
  stroke-width: 1px;
  fill: var(--node-fill-default);
  stroke: var(--node-stroke-default); }

.pipeline-node:focus {
  outline: none; }
  [data-whatinput='keyboard'] .pipeline-node:focus .pipeline-node__bg {
    stroke: #00b0f5;
    stroke-width: 3.5px; }

.pipeline-node--active .pipeline-node__bg {
  fill: var(--node-fill-active);
  stroke: var(--node-stroke-active); }

.pipeline-node--parameters .pipeline-node__bg {
  stroke: var(--parameter-accent); }

.pipeline-node--dataset-input text {
  fill: var(--node-input-fill); }

.pipeline-node--dataset-input .pipeline-node__bg {
  fill: none;
  stroke: var(--node-input-fill);
  stroke-dasharray: 3px 3px; }

.pipeline-node--dataset-input .pipeline-node__icon {
  fill: var(--node-input-fill); }

.pipeline-node--parameter-input text {
  fill: var(--node-input-fill); }

.pipeline-node--parameter-input .pipeline-node__bg {
  fill: none;
  stroke: var(--parameter-accent-input);
  stroke-dasharray: 3px 3px; }

.pipeline-node--parameter-input .pipeline-node__icon {
  fill: var(--node-input-fill); }

.pipeline-node__parameter-indicator {
  opacity: 0;
  transition: opacity 0.3s ease;
  fill: var(--parameter-accent); }
  .pipeline-node__parameter-indicator--visible {
    opacity: 1; }

.pipeline-node--selected .pipeline-node__bg {
  stroke-width: 2px;
  fill: var(--node-fill-selected);
  stroke: var(--node-stroke-selected); }

.pipeline-node--collapsed-hint .pipeline-node__bg {
  stroke: var(--parameter-accent); }

.pipeline-node--faded {
  opacity: 0.3; }

.pipeline-node__icon {
  transition: transform 0.3s ease;
  pointer-events: none;
  fill: var(--node-icon-fill); }

.pipeline-node__text {
  transition: opacity 0.15s ease; }

.pipeline-edge path {
  pointer-events: none;
  fill: none;
  stroke-width: 1.5px;
  stroke: var(--edge-stroke); }

.pipeline-edge--parameters path {
  stroke: var(--parameter-accent); }

.pipeline-edge--parameters-input path {
  stroke: var(--parameter-accent-input); }

.pipeline-edge--dataset--input path {
  stroke-dasharray: 3px 3px; }

.pipeline-edge--faded {
  opacity: 0.1; }

.pipeline-flowchart__arrowhead {
  fill: var(--edge-arrowhead-fill); }

.pipeline-flowchart__arrowhead--input {
  fill: var(--node-input-fill); }

.pipeline-flowchart__arrowhead--accent {
  fill: var(--parameter-accent); }

.pipeline-flowchart__arrowhead--accent--input {
  fill: var(--parameter-accent-input); }

.pipeline-layer {
  opacity: 0;
  transition: opacity ease 0.5s;
  fill: var(--layer-fill); }
  .pipeline-layer:hover {
    opacity: 1; }
  .pipeline-graph--export .pipeline-layer {
    display: none; }
  @media print {
    .pipeline-layer {
      display: none; } }

.kui-theme--light {
  --layer-bg-transparent: rgba(204, 209, 214, 0); }

.kui-theme--dark {
  --layer-bg-transparent: rgba(0, 21, 33, 0); }

.pipeline-flowchart__layer-names {
  position: absolute;
  top: 0;
  left: 0;
  width: 130px;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background: linear-gradient(to right, var(--color-bg-1), var(--layer-bg-transparent));
  opacity: 0;
  transition: opacity ease 0.7s;
  pointer-events: none; }
  .pipeline-flowchart__layer-names--visible {
    opacity: 1; }
  @media print {
    .pipeline-flowchart__layer-names {
      display: none; } }

.pipeline-layer-name {
  position: absolute;
  top: -10px;
  display: flex;
  align-items: center;
  height: 20px;
  padding-left: 18px;
  color: var(--layer-text);
  font-weight: bold;
  font-size: 1.6em;
  white-space: nowrap; }

.pipeline-flowchart {
  height: 100%; }

.pipeline-flowchart__graph {
  display: block; }
  .pipeline-flowchart__graph.pipeline-graph--export.kui-theme--dark {
    background: #001521; }
  @media print {
    .pipeline-flowchart__graph {
      width: 100%;
      height: auto; } }

.pipeline-zoom-wrapper--hidden {
  opacity: 0; }

@media print {
  .pipeline-zoom-wrapper {
    transform: inherit;
    opacity: 1; } }

.pipeline-flowchart__zoom-wrapper--animating {
  shape-rendering: optimizeSpeed;
  text-rendering: optimizeSpeed; }
