.kui-theme--light {
  --minimap-toolbar-active: #f0f1f3; }

.kui-theme--dark {
  --minimap-toolbar-active: #0e2330; }

.pipeline-minimap-toolbar {
  display: block;
  margin-top: auto;
  padding: 0;
  list-style: none; }

.pipeline-minimap-button {
  height: 42px;
  transition: background-color 300ms ease; }

.pipeline-minimap-button.pipeline-icon-toolbar__button--active {
  background: var(--minimap-toolbar-active); }

.pipeline-minimap-button--map {
  padding-top: 4px; }

.pipeline-minimap-toolbar__scale {
  display: block;
  width: 100%;
  padding: 6px 8px 6px;
  color: var(--color-text);
  font-weight: bold;
  font-size: 10px;
  text-align: center; }
