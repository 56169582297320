.pipeline-loading-icon {
  display: block;
  width: 30px;
  height: auto;
  opacity: 0;
  pointer-events: none; }
  .pipeline-loading-icon--visible {
    opacity: 1; }
  .pipeline-loading-icon path {
    fill: none;
    stroke-width: 10px; }
  .pipeline-loading-icon path:nth-of-type(1) {
    animation: stroke-spin1 2s infinite linear;
    stroke: #00b0f5;
    stroke-dasharray: 89.52433, 447.62167; }
  .pipeline-loading-icon path:nth-of-type(2) {
    animation: stroke-spin2 2s infinite linear;
    stroke-dasharray: 417.78022, 119.36578;
    stroke: var(--color-bg-alt); }

@keyframes stroke-spin1 {
  from {
    stroke-dashoffset: 223.81083; }
  to {
    stroke-dashoffset: -313.33517; } }

@keyframes stroke-spin2 {
  from {
    stroke-dashoffset: 119.36578; }
  to {
    stroke-dashoffset: -417.78022; } }
