@charset "UTF-8";
.pipeline-nodelist-search {
  padding: 2px 1.4em 0 1.4em; }
  .pipeline-nodelist-search .kui-searchbar,
  .pipeline-nodelist-search .kui-input-wrapper {
    max-width: none; }
  .pipeline-nodelist-search .kui-searchbar .kui-icon {
    opacity: 1; }
  .pipeline-nodelist-search .kui-searchbar .kui-icon--close {
    top: 18px;
    right: 2px;
    width: 20px;
    height: 20px; }
  .pipeline-nodelist-search .kui-searchbar .kui-icon__graphics {
    opacity: 0.45;
    fill: var(--color-default-alt) !important; }
  .pipeline-nodelist-search .kui-searchbar--focused .kui-icon__graphics {
    opacity: 0.75; }
  .pipeline-nodelist-search .kui-input {
    padding: 5px; }
  .pipeline-nodelist-search .kui-input:active:not(.kui-input--disabled),
  .pipeline-nodelist-search .kui-input:hover:not([disabled]),
  .pipeline-nodelist-search .kui-input,
  .pipeline-nodelist-search .kui-input--focused,
  .pipeline-nodelist-search .kui-input__field {
    background: none;
    border: none;
    box-shadow: none; }
  .pipeline-nodelist-search .kui-input--focused {
    position: relative;
    z-index: 1;
    outline-color: #00b0f5; }
  .pipeline-nodelist-search .kui-input__field::placeholder {
    opacity: 1; }
  .pipeline-nodelist-search .kui-input__field::placeholder {
    color: var(--color-default-alt) !important;
    opacity: 0.5; }
  .pipeline-nodelist-search .kui-input__field:hover::placeholder {
    opacity: 0.7; }
  .pipeline-nodelist-search .kui-input__field:focus::placeholder {
    opacity: 0.95; }
  .pipeline-nodelist-search .kui-input__field {
    margin: 0 0 2px 32px;
    font-size: 1.65em; }
  .pipeline-nodelist-search .kui-searchbar__iconwrapper .kui-icon {
    top: 16px;
    left: 6px; }
  .pipeline-nodelist-search .kui-input__line:before {
    flex: 1;
    max-width: none; }
  .pipeline-nodelist-search .kui-input__line:before {
    background-color: var(--color-default-alt);
    opacity: 0.3;
    transition: background-color 0.3s, opacity 0.3s; }
  .pipeline-nodelist-search .kui-searchbar--focused .kui-input__line:before {
    opacity: 0.55; }
  .pipeline-nodelist-search .kui-searchbar .kui-input__line:not([data-value='']):before {
    background-color: #00b0f5;
    opacity: 1; }
  .pipeline-nodelist-search .kui-input__line:after {
    display: none; }

.pipeline-nodelist-section__title {
  display: block;
  margin: 6px 1.54em 12px 1.74em;
  font-weight: normal;
  font-size: 1.48em;
  opacity: 0.65;
  user-select: none; }

.pipeline-nodelist__list {
  margin: 0;
  padding: 0;
  list-style: none; }

.pipeline-nodelist__list--nested {
  margin: 0 0 1.2em; }

.pipeline-nodelist__list .pipeline-nodelist__children {
  overflow: hidden; }
  .pipeline-nodelist__list .pipeline-nodelist__children--closed {
    display: none; }

.pipeline-nodelist__placeholder-upper,
.pipeline-nodelist__placeholder-lower {
  z-index: 2;
  pointer-events: none; }

.pipeline-nodelist__placeholder-upper:after,
.pipeline-nodelist__placeholder-lower:after {
  position: absolute;
  width: 100%;
  height: 120px;
  opacity: 0;
  transition: opacity ease 0.3s;
  content: ' ';
  pointer-events: none; }

.pipeline-nodelist__filter-panel .pipeline-nodelist__placeholder-upper:after {
  bottom: -120px;
  background: linear-gradient(0deg, var(--color-nodelist-bg-filter-transparent) 0%, var(--color-nodelist-filter-panel) 100%); }

.pipeline-nodelist__filter-panel .pipeline-nodelist__placeholder-lower:after {
  top: -120px;
  background: linear-gradient(0deg, var(--color-nodelist-filter-panel) 0%, var(--color-nodelist-bg-filter-transparent) 100%); }

.pipeline-nodelist__placeholder-upper--fade:after,
.pipeline-nodelist__placeholder-lower--fade:after {
  opacity: 1; }

.pipeline-nodelist__heading {
  position: sticky;
  top: 0;
  z-index: 1;
  margin: 0;
  transform: translateY(-1px); }
  .pipeline-nodelist__heading .pipeline-nodelist__row__text {
    position: relative;
    opacity: 0.65; }

.pipeline-nodelist__elements-panel .pipeline-nodelist__heading {
  background: var(--color-nodelist-element-panel); }
  .pipeline-nodelist__elements-panel .pipeline-nodelist__heading:after {
    position: absolute;
    bottom: -19px;
    z-index: -1;
    width: 100%;
    height: 20px;
    background: linear-gradient(0deg, var(--color-nodelist-bg-transparent) 0%, var(--color-nodelist-element-panel) 100%);
    transition: opacity ease 0.3s;
    content: ' ';
    pointer-events: none; }

.pipeline-nodelist__filter-panel .pipeline-nodelist__heading {
  background: var(--color-nodelist-filter-panel); }
  .pipeline-nodelist__filter-panel .pipeline-nodelist__heading:after {
    position: absolute;
    bottom: -19px;
    z-index: -1;
    width: 100%;
    height: 20px;
    background: linear-gradient(0deg, var(--color-nodelist-bg-transparent) 0%, var(--color-nodelist-filter-panel) 100%);
    transition: opacity ease 0.3s;
    content: ' ';
    pointer-events: none; }

.pipeline-type-group-toggle {
  width: 2.8em;
  height: 2.8em;
  padding: 0;
  color: var(--color-default-alt);
  font-size: inherit;
  font-family: inherit;
  line-height: 1em;
  text-align: center;
  background: none;
  border: none;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  transition: transform ease 0.1s; }
  .pipeline-type-group-toggle:focus {
    outline: none; }
    [data-whatintent='keyboard'] .pipeline-type-group-toggle:focus {
      box-shadow: 0 0 0 3px rgba(0, 176, 245, 0.85) inset; }
  .pipeline-type-group-toggle:before {
    font-size: 1.8em;
    opacity: 0.55;
    content: '▾'; }
  .pipeline-type-group-toggle:hover:before {
    opacity: 1; }
  .pipeline-type-group-toggle--alt {
    transform: rotate(90deg); }

.pipeline-nodelist__row {
  position: relative;
  display: flex;
  align-items: center;
  height: 37px;
  transform: translate(0, 0);
  cursor: default; }
  .pipeline-nodelist__row--kind-filter {
    padding: 0 1.6em 0 2.6em; }
  .pipeline-nodelist__row--active, .pipeline-nodelist__row--visible:hover {
    background-color: var(--color-nodelist-row-active); }
  .pipeline-nodelist__row--selected, .pipeline-nodelist__row--visible.pipeline-nodelist__row--selected {
    background-color: var(--color-nodelist-row-selected); }
  .pipeline-nodelist__row--disabled {
    pointer-events: none; }

.pipeline-nodelist__row__icon {
  display: block;
  flex-shrink: 0;
  width: 2.2em;
  height: 2.2em;
  fill: var(--color-text); }
  .pipeline-nodelist__row__icon.pipeline-row__toggle-icon--focus-checked {
    fill: #00b0f5; }
  .pipeline-nodelist__row__icon--disabled > * {
    opacity: 0.1; }

.pipeline-nodelist__row__type-icon--nested > * {
  opacity: 0.3; }

.pipeline-nodelist__row__type-icon--faded > * {
  opacity: 0.2; }

.pipeline-nodelist__row__type-icon--active > *, .pipeline-nodelist__row__type-icon--selected > *,
.pipeline-nodelist__row--visible:hover .pipeline-nodelist__row__type-icon > *,
[data-whatintent='keyboard'] .pipeline-nodelist__row__text:focus .pipeline-nodelist__row__type-icon > * {
  opacity: 1; }

.pipeline-nodelist__row__type-icon--active--faded > *, .pipeline-nodelist__row__type-icon--selected--faded > *,
.pipeline-nodelist__row--visible:hover .pipeline-nodelist__row__type-icon--faded > *,
[data-whatintent='keyboard'] .pipeline-nodelist__row__text:focus .pipeline-nodelist__row__type-icon--faded > * {
  opacity: 0.55; }

.pipeline-nodelist__elements-panel .MuiTreeItem-label {
  width: calc(100% - 19px); }

.pipeline-nodelist__row__text {
  display: flex;
  align-items: center;
  width: calc(100% - 7em);
  margin-right: auto;
  padding: 0.68em 0 0.68em 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.6;
  letter-spacing: inherit;
  text-align: inherit;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: default;
  user-select: none; }
  .pipeline-nodelist__row__text--tree {
    padding: 0.68em 0 0.68em 1em; }
  .pipeline-nodelist__row__text:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 176, 245, 0.85) inset; }
    [data-whatintent='mouse'] .pipeline-nodelist__row__text:focus {
      box-shadow: none; }

.pipeline-nodelist__row__label {
  overflow: hidden;
  font-size: 1.48em;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .pipeline-nodelist__row__label--faded {
    opacity: 0.65; }
  .pipeline-nodelist__row__label--disabled {
    opacity: 0.3; }
  .pipeline-nodelist__row__label b {
    color: var(--color-nodelist-highlight);
    font-weight: normal; }

.pipeline-nodelist__row__count {
  display: inline-block;
  flex-shrink: 0;
  width: 2.2em;
  margin: 0 0.7em 0.1em auto;
  overflow: hidden;
  font-size: 1.16em;
  text-align: right;
  text-overflow: ellipsis;
  opacity: 0.75;
  user-select: none; }
  .pipeline-nodelist__row--unchecked .pipeline-nodelist__row__count {
    opacity: 0.55; }

.pipeline-nodelist__row--unchecked .pipeline-nodelist__row__label--kind-filter {
  opacity: 0.55; }

.pipeline-nodelist__row--unchecked:hover .pipeline-nodelist__row__label--kind-filter {
  opacity: 0.8; }

.pipeline-nodelist__group--all-unchecked .pipeline-nodelist__row--unchecked .pipeline-nodelist__row__label--kind-filter {
  opacity: 1; }

.pipeline-row__toggle {
  cursor: pointer; }
  .pipeline-row__toggle--kind-element {
    margin-right: 8px; }
  .pipeline-row__toggle--disabled {
    display: none; }

.kui-theme--light {
  --row-selected-transparent: rgba(217, 220, 225, 0); }

.kui-theme--dark {
  --row-selected-transparent: rgba(11, 53, 75, 0); }

.pipeline-row__toggle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100px;
  display: block;
  width: 100px;
  background: transparent;
  background: linear-gradient(90deg, var(--row-selected-transparent, 0) 0%, var(--color-nodelist-row-selected) 100%);
  transform: translate(0, 0);
  opacity: 0;
  content: ' ';
  pointer-events: none; }

.pipeline-row__toggle--selected:before {
  opacity: 1; }

.pipeline-nodelist__row__checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%); }

.pipeline-row__toggle-icon {
  width: 2.7em;
  height: 2.7em;
  padding: 0.3em;
  border-radius: 50%; }
  .pipeline-nodelist__row__checkbox:focus + .pipeline-row__toggle-icon {
    outline: none; }
    [data-whatintent='keyboard'] .pipeline-nodelist__row__checkbox:focus + .pipeline-row__toggle-icon {
      box-shadow: 0 0 0 3px rgba(0, 176, 245, 0.85) inset; }

/*
 | row-hover | icon-hover | checked | opacity |
 |    🚫     |     🚫      |    🚫   |    0    |
 |    🚫     |     🚫      |    ✅   |    0    |
 |    ✅     |     🚫      |    🚫   |   0.55  |
 |    ✅     |     🚫      |    ✅   |   0.55  |
 |    ✅     |     ✅      |    🚫   |    1    |
 |    ✅     |     ✅      |    ✅   |    1    |
 */
.pipeline-row__toggle-icon--kind-element > * {
  opacity: 0; }

.pipeline-nodelist__row:hover .pipeline-row__toggle-icon--kind-element > * {
  opacity: 0.55; }

.pipeline-nodelist__row .pipeline-row__toggle-icon--kind-element:hover > * {
  opacity: 1; }

[data-whatintent='keyboard'] input:focus + .pipeline-row__toggle-icon--kind-element > * {
  opacity: 0.55; }

[data-whatintent='keyboard'] input:focus + .pipeline-row__toggle-icon--kind-element.pipeline-row__toggle-icon--checked > * {
  opacity: 1; }

.pipeline-row__toggle-icon--kind-element.pipeline-row__toggle-icon--focus-checked > * {
  opacity: 1; }

/*
 Parent (toggle all tags):
 | row-hover | icon-hover | checked | opacity |
 |    🚫     |     🚫      |    🚫   |    0    |
 |    ✅     |     🚫      |    🚫   |   0.3   |
 |    ✅     |     ✅      |    🚫   |   0.55  |
 |    🚫     |     🚫      |    ✅   |   0.55  |
 |    ✅     |     ✅      |    ✅   |   0.75  |

 Child (individual tag):
 | row-hover | checked | opacity |
 |    🚫     |    🚫    |    0    |
 |    ✅     |    🚫    |   0.3   |
 |    🚫     |    ✅    |   0.55  |
 |    ✅     |    ✅    |   0.75  |
 */
.pipeline-row__toggle-icon--kind-filter > * {
  opacity: 0.55; }

.pipeline-nodelist__heading .pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--all-unchecked > * {
  opacity: 0; }

.pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--all-unchecked > * {
  opacity: 0.55; }

.pipeline-nodelist__row:hover .pipeline-row__toggle-icon--kind-filter > * {
  opacity: 0.55; }

.pipeline-nodelist__row:hover .pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--parent:hover > * {
  opacity: 0.9; }

.pipeline-nodelist__row .pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--checked > * {
  opacity: 0.9; }

.pipeline-nodelist__row:hover .pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--child.pipeline-row__toggle-icon--checked > * {
  opacity: 1; }

.pipeline-nodelist__row .pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--parent:hover.pipeline-row__toggle-icon--checked > * {
  opacity: 1; }

[data-whatintent='keyboard'] input:focus + .pipeline-row__toggle-icon--kind-filter > * {
  opacity: 0.9; }

[data-whatintent='keyboard'] input:focus + .pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--checked > * {
  opacity: 1; }

.pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--checked {
  fill: var(--color-nodelist-filter-indicator-on);
  stroke: var(--color-nodelist-filter-indicator-on); }

.pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--unchecked {
  fill: none;
  stroke: var(--color-nodelist-filter-indicator-off); }

.pipeline-nodelist__row:hover .pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--all-unchecked, .pipeline-row__toggle-icon--kind-filter.pipeline-row__toggle-icon--parent {
  fill: #00b0f5;
  stroke: #00b0f5; }

.pipeline-nodelist__filter-panel {
  z-index: 1;
  background: var(--color-nodelist-filter-panel); }
  .pipeline-nodelist__filter-panel .pipeline-nodelist-section:last-child {
    padding-bottom: 28px;
    padding-top: 4px; }

.pipeline-nodelist__elements-panel .pipeline-nodelist-section:last-child {
  padding-bottom: 28px;
  padding-top: 12px; }

.pipeline-nodelist__split {
  position: absolute;
  top: 58px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column; }
  .pipeline-nodelist__split--resizing {
    cursor: row-resize; }

.pipeline-nodelist__split-handle {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  height: 16px;
  border-top: 1px solid transparent;
  cursor: row-resize;
  transition: border-color ease 0.1s 0.15s; }
  .pipeline-nodelist__split-handle:after {
    position: absolute;
    top: 7px;
    left: 50%;
    display: block;
    width: 50px;
    height: 2px;
    background: var(--color-nodelist-split-handle);
    border-radius: 2px;
    transform: translate(-50%, 0);
    opacity: 0;
    transition: opacity ease 0.1s 0.15s;
    content: ' ';
    pointer-events: none; }
  .pipeline-nodelist__filter-panel:hover .pipeline-nodelist__split-handle:after {
    opacity: 0.2; }
  .pipeline-nodelist__split-handle:focus {
    z-index: 1;
    outline: 3px solid rgba(0, 176, 245, 0.85); }
  .pipeline-nodelist__split-handle:hover {
    border-top: 1px solid var(--color-nodelist-split-handle-border); }
  .pipeline-nodelist__filter-panel:hover .pipeline-nodelist__split-handle:hover:after {
    opacity: 0.3; }
  .pipeline-nodelist__split--resizing .pipeline-nodelist__split-handle {
    border-top: 1px solid rgba(0, 176, 245, 0.85); }

.pipeline-nodelist-scrollbars {
  flex-grow: 1; }
  .pipeline-nodelist-scrollbars:after {
    position: absolute;
    top: 0;
    right: -1px;
    left: -1px;
    height: 22px;
    background: linear-gradient(0deg, var(--color-nodelist-bg-transparent) 0%, var(--color-bg-3) 100%);
    content: '';
    pointer-events: none; }
  .pipeline-nodelist__filter-panel .pipeline-nodelist-scrollbars:after {
    display: none; }

.kui-theme--light {
  --color-nodelist-row-active: #e8e9ec;
  --color-nodelist-row-selected: #d9dce1;
  --color-nodelist-filter-indicator-on: #00b0f5;
  --color-nodelist-filter-indicator-off: rgba(0, 0, 0, 0.55);
  --color-nodelist-highlight: #008bc2;
  --color-nodelist-split-handle: #555;
  --color-nodelist-split-handle-border: rgba(85, 85, 85, 0.18);
  --color-nodelist-filter-panel: #f8f9fa;
  --color-nodelist-element-panel: #f0f1f3; }

.kui-theme--dark {
  --color-nodelist-row-active: #082535;
  --color-nodelist-row-selected: #0b354b;
  --color-nodelist-filter-indicator-on: #00b0f5;
  --color-nodelist-filter-indicator-off: rgba(255, 255, 255, 0.45);
  --color-nodelist-highlight: #00b0f5;
  --color-nodelist-split-handle: #fff;
  --color-nodelist-split-handle-border: rgba(255, 255, 255, 0.15);
  --color-nodelist-filter-panel: #122531;
  --color-nodelist-element-panel: #061c28; }

.kui-theme--light {
  --color-nodelist-bg-transparent: rgba(240, 241, 243, 0); }

.kui-theme--dark {
  --color-nodelist-bg-transparent: rgba(6, 28, 40, 0); }

.kui-theme--light {
  --color-nodelist-bg-filter-transparent: rgba(248, 249, 250, 0); }

.kui-theme--dark {
  --color-nodelist-bg-filter-transparent: rgba(18, 37, 49, 0); }

.pipeline-nodelist {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 1.8em; }
  .pipeline-nodelist:before,
  .pipeline-nodelist > * {
    transition: opacity ease 0.2s; }
  .pipeline-nodelist:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--color-bg-2);
    opacity: 0;
    content: ''; }
  .pipeline-nodelist--fade > * {
    opacity: 0.2; }
  .pipeline-nodelist--fade:before {
    opacity: 1; }
