.kui-theme--light {
  --color-modal-header-text: rgba(0, 0, 0, 0.55);
  --color-toggle--on: #107da5;
  --color-toggle-bar--on: rgba(0, 176, 245, 0.3);
  --color-toggle--off: #515151;
  --color-toggle-bar--off: rgba(81, 81, 81, 0.3); }

.kui-theme--dark {
  --color-modal-header-text: rgba(255, 255, 255, 0.55);
  --color-toggle--on: #00b0f5;
  --color-toggle-bar--on: rgba(0, 176, 245, 0.3);
  --color-toggle--off: #f0f1f3;
  --color-toggle-bar--off: #081c28; }

.pipeline-settings-modal .kui-modal__content {
  width: calc(100% - 5em);
  max-width: 900px; }

.pipeline-settings-modal .kui-modal__wrapper {
  align-items: flex-start;
  text-align: left; }

.pipeline-settings-modal .kui-modal__bg--visible {
  opacity: 0.7; }

.pipeline-settings-modal__content {
  width: 100%;
  margin-bottom: 4em;
  font-size: 1.25em; }
  .pipeline-settings-modal__content .kui-button {
    margin: 0 10px; }

.pipeline-settings-modal__column,
.pipeline-settings-modal__header {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2em; }

.pipeline-settings-modal__header {
  margin-bottom: 10px;
  color: var(--color-modal-header-text); }

.pipeline-settings-modal__subtitle {
  margin-bottom: 10px;
  font-size: 1.5em; }

.pipeline-settings-modal__name {
  flex: 0 0 calc(100% / 12 * 3);
  margin-top: 10px; }

.pipeline-settings-modal__description {
  flex: 0 0 calc(100% / 12 * 6);
  margin-top: 10px; }

.pipeline-settings-modal__state {
  flex: 0 0 calc(100% / 12 * 3);
  margin-top: 10px; }
