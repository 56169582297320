.kui-theme--light {
  --color-default: white;
  --color-default-alt: black;
  --color-text: rgba(0, 0, 0, 0.85);
  --color-text-alt: rgba(255, 255, 255, 0.85);
  --color-bg-1: #ccd1d6;
  --color-bg-2: #e6e8eb;
  --color-bg-3: #f0f1f3;
  --color-bg-4: #f8f9fa;
  --color-bg-alt: #242525;
  --color-sidebar-background: #f0f1f3;
  --color-sidebar-border: rgba(0, 0, 0, 0.05);
  --color-sidebar-shadow: rgba(0, 0, 0, 0.07); }

.kui-theme--dark {
  --color-default: black;
  --color-default-alt: white;
  --color-text: rgba(255, 255, 255, 0.85);
  --color-text-alt: rgba(0, 0, 0, 0.85);
  --color-bg-1: #001521;
  --color-bg-2: #031a26;
  --color-bg-3: #061c28;
  --color-bg-4: #0e2330;
  --color-bg-5: #122531;
  --color-bg-6: #2a3b46;
  --color-bg-alt: white;
  --color-sidebar-background: #031a26;
  --color-sidebar-border: rgba(0, 0, 0, 0.2);
  --color-sidebar-shadow: rgba(0, 0, 0, 0.3); }
