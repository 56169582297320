.kedro-pipeline {
  position: relative;
  height: 100%;
  overflow: hidden;
  color: var(--color-text);
  background: var(--color-bg-1); }
  @media print {
    .kedro-pipeline.kui-theme--light {
      background: none; } }

.pipeline-title {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%); }

.pipeline-wrapper {
  height: 100%; }

.pipeline-wrapper__loading {
  position: absolute;
  right: 20px;
  bottom: 20px; }
