.pipeline-toolbar__label {
  color: var(--color-text-alt);
  background: var(--color-bg-alt);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }

.pipeline-icon-toolbar__button {
  position: relative;
  width: 56px;
  height: 56px;
  padding: 0;
  color: inherit;
  font-size: 1.1em;
  text-align: center;
  vertical-align: middle;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  user-select: none; }
  @media (max-height: 550px) {
    .pipeline-icon-toolbar__button {
      height: 42px; } }
  .pipeline-icon-toolbar__button:focus {
    outline: none; }
    [data-whatinput='keyboard'] .pipeline-icon-toolbar__button:focus {
      outline: 3px solid rgba(0, 176, 245, 0.85); }
  .pipeline-icon-toolbar__button:hover svg {
    opacity: 1; }
  .pipeline-icon-toolbar__button:disabled {
    cursor: not-allowed; }
    .pipeline-icon-toolbar__button:disabled svg {
      opacity: 0.2; }
  .pipeline-icon-toolbar__button svg {
    width: 1.8em;
    height: 1.8em; }

.pipeline-toolbar__label {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  margin-top: -1.2em;
  margin-left: 1.6em;
  padding: 0.5em 0.7em;
  font-size: 1.4em;
  white-space: nowrap; }
  @media (max-width: 700px) {
    .pipeline-sidebar--visible .pipeline-toolbar__label {
      right: 50%;
      left: auto;
      margin-right: 1.6em;
      margin-left: auto; } }
  .pipeline-icon-toolbar__button:hover .pipeline-toolbar__label,
  [data-whatinput='keyboard'] .pipeline-icon-toolbar__button:focus .pipeline-toolbar__label {
    display: inline-block; }
  .pipeline-toolbar__label::after {
    position: absolute;
    top: calc(50% - 7px);
    left: -6.5px;
    width: 0;
    height: 0;
    border-color: transparent var(--color-bg-alt) transparent transparent;
    border-style: solid;
    border-width: 7px 7px 7px 0;
    content: ''; }
    @media (max-width: 700px) {
      .pipeline-sidebar--visible .pipeline-toolbar__label::after {
        right: -6.5px;
        left: auto;
        border-color: transparent transparent transparent var(--color-bg-alt);
        border-width: 7px 0 7px 7px; } }

.pipeline-icon {
  opacity: 0.7;
  transition: opacity 300ms ease;
  fill: var(--color-default-alt); }
