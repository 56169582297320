.pipeline-sidebar {
  background: var(--color-sidebar-background);
  border-bottom: 1px solid var(--color-sidebar-border);
  box-shadow: 0 1px 3px var(--color-sidebar-shadow); }

.pipeline-sidebar {
  position: absolute;
  top: -1px;
  /* Avoids pixel rounding gaps */
  bottom: -1px;
  left: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  transform: translateX(calc(-100% + 56px));
  transition: transform ease 0.4s; }
  @media (min-width: 1200px) {
    .pipeline-sidebar {
      z-index: 3; } }
  @media (min-width: 700px) {
    .pipeline-sidebar {
      width: 400px;
      transform: translateX(-344px); } }
  .pipeline-sidebar--visible {
    transform: translateX(0); }
  @media print {
    .pipeline-sidebar {
      display: none; } }
  .pipeline-sidebar--visible {
    transform: translateX(0); }

.pipeline-ui {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  background: var(--color-bg-3);
  visibility: hidden;
  transition: visibility 0.3s; }
  .pipeline-sidebar--visible .pipeline-ui {
    visibility: visible;
    transition: visibility 0s; }

.pipeline-toolbar {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 100%;
  background: var(--color-bg-2); }
